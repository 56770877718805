import {browserName, browserVersion, isMobile, isTablet, osName, osVersion} from 'react-device-detect';
import {BillingRateOption, MembershipApplication} from "../models/MembershipApplication";
import {AppConfig} from "../store/app-context";
import {Payment} from "../models/Payment";
import {Transaction} from "../models/Transaction";

/* Endpoints */
export const writeToLogsEndpoint: string = '/logs/write';
export const updateMembershipApplicationEndpoint = (token: string): string => {
    return `/membership/update/${token}`;
};
export const industryEndpoint: string = '/industry';
export const countriesEndpoint: string = '/geo/countries';
export const statesEndpoint = (countryCode: string) => {
    return `/geo/states/${countryCode}`;
}
export const updateSalesforceContactEndpoint = (applicationId: number) => {
    return `/salesforce/contact?appId=${applicationId}`;
}
export const getInvoiceEndpoint = (token: string) => {
    return `/invoice/${token}`;
}
export const getProcessPaymentEndpoint = (isDelayedPayment: boolean = false) => {
    return `/membership/payment?delayPayment=${isDelayedPayment}`
}
export const createPayFabricTokenEndpoint = `/payfabric/token/create`;
export const createPayFabricTransactionEndpoint = (id: number) => {
    return `/payfabric/transaction/create?appId=${id}`;
}
export const getPayFabricProcessTrxUrl = (key: string, payFabricBaseUrl: string, token: string, street1: string, street2: string, city: string, state: string, zip: string, country: string, email: string, delayPayment: boolean) => {
    return encodeURI(`${payFabricBaseUrl}/Payment/Web/Transaction/Process?key=${key}&token=${token}&ReturnUri=${window.location.origin}/payment-response&Street1=${street1}&Street2=${street2}&City=${city}&State=${state}&Zip=${zip}&Country=${country}&Email=${email}&delayPayment=${delayPayment}`);
}
export const getTransactionEndpoint = (id: number, key: string) => {
    return `/payfabric/transaction/${key}?appId=${id}`;
}
export const getNotifyAuthorizerUrl = (token: string) => {
    return `/membership/notifyAuthorizer/${token}`;
}


/* Dropdown values */
export const prefixValues = [
    { value: 'Mr', display: 'Mr.' },
    { value: 'Mrs', display: 'Mrs.' },
    { value: 'Ms', display: 'Ms.' },
    { value: 'Dr', display: 'Dr.' },
    { value: 'Prof', display: 'Prof.' }
];
export const genderValues = [
    { value: 'Female', display: 'Female' },
    { value: 'Male', display: 'Male' },
    { value: 'Prefer not to specify', display: 'Prefer not to specify' },
    { value: 'Other', display: 'Other' }
];
export const typeOfOrganizationValues = [
    { value: 'Public', display: 'Public' },
    { value: 'Private', display: 'Private' },
    { value: 'Not-for-profit', display: 'Not-for-profit' },
    { value: 'Government', display: 'Government' },
    { value: 'Family-owned', display: 'Family-owned' }
];

export const annualRevenueValues = [
    { value: 'Less than 500k', display: 'Less than 500k' },
    { value: '500-999k', display: '500-999k' },
    { value: '1-4 Million', display: '1-4 Million' },
    { value: '5-9 Million', display: '5-9 Million' },
    { value: '10-20 Million', display: '10-20 Million' },
    { value: '21-49 Million', display: '21-49 Million' },
    { value: '50-99 Million', display: '50-99 Million' },
    { value: '100-249 Million', display: '100-249 Million' },
    { value: '250-499 Million', display: '250-499 Million' },
    { value: '500-999 Million', display: '500-999 Million' },
    { value: '1+ Billion', display: '1+ Billion' }
];

export const numberOfEmployeesValues = [
    { value: '0', display: '0' },
    { value: '1-9', display: '1 - 9' },
    { value: '10-19', display: '10 - 19' },
    { value: '20-49', display: '20 - 49' },
    { value: '50-99', display: '50 - 99' },
    { value: '100-499', display: '100 - 499' },
    { value: '500-999', display: '500 - 999' },
    { value: '1000-4999', display: '1000 - 4999' },
    { value: '5000-9999', display: '5000 - 9999' },
    { value: '10000+', display: '10000+' }
];

export const paymentMethods = [
    { value: 'EFT', display: 'Recurring Electronic Funds Transfer' },
    { value: 'CC', display: 'Recurring Credit Card' },
    { value: 'INV', display: 'Recurring Invoicing' }
];
export const taPaymentTypes = [
    { value: 'DUES', display: 'Dues Offset' },
    { value: 'CASH', display: 'Cash' },
    { value: 'OPT', display: 'Opt Out' }
];
export const payFabricCustomMessages = ['closePaymentIFrame' , 'paymentIFrameLoaded'];
export const maxRetryCount = 5;

/* Helper functions */
export const isNotEmpty = (value: string): boolean => {
    return value.trim().length > 0;
};
export const sanitizeValue = (value: string) => {
    return value === '' || value === undefined || value === null ? null : value
}
export const isEmailValid = (value: string) => {
    return /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(value);
}
export const isRoutingNumberValid = (value: string) => {
    return /^([0-3]|6|7)\d{1,8}$/.test(value);
}

/* Payment information page helper functions*/
export const transformAmount = (value: number | undefined) => {
    if (value === undefined) return '';
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    }).format(value);
}
export const getSelectedMethodOfPaymentText = (value: string) => {
    if (!value) return value;
    if (value === 'CC') return 'credit card';
    if (value === 'INV') return 'invoicing';
    if (value === 'EFT') return 'electronic funds transfer';
}
export const feesDueLabel = (groupType: string, billingFrequency: BillingRateOption, methodOfPayment: string) => {
    if (groupType === 'EL-SAND') {
        return billingFrequency?.frequency === 'Pre-Pay Two Years'
            ? 'Program Fee' : billingFrequency?.frequencyLabel + ' Membership Fees';
    }
    if (methodOfPayment === 'INV') {
        return billingFrequency?.frequencyLabel + ' Membership Fees';
    }
    return 'First Month Dues';
}
export const getDiscountsTotal = (membershipApplication: MembershipApplication)=> {
    let discountsTotal = 0;
    membershipApplication?.appliedDiscounts?.forEach((discount) => {
        discountsTotal += discount.amount;
    })
    return discountsTotal;
}
export const getFirstMonthDue = (membershipApplication: MembershipApplication, billingFrequency: BillingRateOption | undefined, methodOfPayment: string) => {
    if (billingFrequency && (membershipApplication?.groupType === 'EL-SAND' || methodOfPayment === 'INV')) {
        return billingFrequency.amount;
    }
    const monthlyBillingRateOption = membershipApplication?.billingRateOption.filter(item => item.frequency === "Monthly");
    if (monthlyBillingRateOption) {
        return monthlyBillingRateOption[0].amount;
    }
    return membershipApplication?.billingRateOption[0].amount;
}
export const getAmountDueLabel = (membershipApplication: MembershipApplication, methodOfPayment: string) => {
    const defaultLabel = 'Amount Due Today';
    if (!membershipApplication || (membershipApplication && !methodOfPayment)) {
        return defaultLabel;
    }
    if (membershipApplication.isDelayedPayment) {
        return 'Amount Due at Group Start';
    }
    return defaultLabel;
}

/* Logging statements */
export const loadingMembershipCommitments = (token: string | undefined): string => {
    return `Loading Membership Commitments for token ${token}...`;
};
export const loadingMemberInformation = (token: string | undefined): string => {
    return `Loading Member Information for token ${token}...`;
}
export const loadingPaymentInformation = (token: string | undefined): string => {
    return `Loading Payment Information for token ${token}...`;
}
export const failedToLoadIndustryData = (token: string | undefined): string => {
    return `Failed to load Industry data for token ${token}...`;
}
export const failedToLoadCountryData = (token: string | undefined): string => {
    return `Failed to load Country data for token ${token}...`;
}
export const failedToLoadStatesData = (token: string | undefined): string => {
    return `Failed to load States data for token ${token}...`;
}
export const processingPayment = (token: string | undefined) => {
    return `Processing Payment for token ${token}...`;
}
export const errorPostingToGP = 'Failed to write data to GP.';
export const errorLoadingDataMessage = 'Failed to load data. Please refresh the page and if the problem persists, contact Vistage Support.';
export const updatingApplication = 'Updating Application';
export const errorUpdatingApplication = 'An error occurred while updating application';
export const errorUpdatingSalesforceContact = 'An error occurred while updating Salesforce contact';
export const errorUpdatingApplicationWithIdAndToken = (id: number, token: string) => {
    return errorUpdatingApplication + ' with application id ' + id + ' and token ' + token;
}
export const errorPayFabricDefault = 'The credit card processor is temporarily unavailable. Please try again later or select a different payment method (Invoice or EFT).';
export const errorGenerationPayFabricTransaction = 'An error occurred while generating PayFabric transaction';
export const errorGeneratingPayFabricToken = 'An error occurred while generating PayFabric token.';
export const receivedResponseFromPayFabric = 'Received response from PayFabric. Sending details back to Payment Information page';
export const receivedEvent = 'Received event';
export const duplicatePaymentMessageTitle = 'Payment Received'
export const duplicatePaymentMessageContent = 'Thank you, we have already accepted a payment for membership. Please press OK to submit the application.';
export const duplicateMessagesReceived = 'Duplicate messages received withing small time frame';
export const receivedTransactionResponseAndFetchingTrx = 'Received Transaction Response And Fetching Transaction';
export const errorStatusMoreInfo: string = 'Missing required information for processing your payment. Please fill all required fields when submitting payment information.';
export const systemError = 'Received a system error.';
export const errorCodeREJECT: string = 'Please check your credit card number and expiration date to make sure they were entered correctly. If this does not resolve the problem, please select another card.';
export const errorCode11 = 'The system timed out waiting for a response. Please try your transaction again.';
export const errorCode12 = 'Please check your credit card number and expiration date to make sure they were entered correctly. If this does not resolve the problem, please select another card.';
export const errorCode19 = 'The system timed out waiting for a response.  Please try your transaction again.';
export const errorCode22 = 'Invalid bank routing number. Please check your account information and re-submit.';
export const errorCode23 = 'Invalid account number. Please check your credit card number and re-submit.';
export const errorCode24 = 'Invalid expiration date. Please check your expiration date and re-submit.';
export const errorCode26 = 'The credit card processor is temporarily unavailable.  Please try again later or select a different payment method (Invoice or EFT).';
export const errorCode30 = 'This transaction has already been processed.';
export const errorCode50 = 'The Amount Due Today exceeds the available funds in this account. Please try a different card or payment method.';
export const errorCode51 = 'The Amount Due Today exceeds the transaction limit on this account. Please try a different card or payment method.';
export const errorCode102 = 'The credit card processor is temporarily unavailable. Please try again later or select a different payment method (Invoice or EFT).';
export const errorCode104 = 'The system timed out waiting for a response.  Please try your transaction again.';
export const errorCode109 = 'The system timed out waiting for a response.  Please try your transaction again.';
export const errorCode114 = 'Invalid security code. Please check your security code and re-submit.';
export const errorCode115 = 'System busy, please try again later.';
export const errorCode12x = 'Please contact your card issuer to verify this transaction. The issuer requires you to review and manually accept the charge before it will be processed.';
export const errorCodeDefault = 'We encountered a problem processing your payment with this account. Please try a different credit card or different payment method (invoice or EFT).';
export const failedToRetrieveTransaction = 'Failed to retrieve transaction';
export const successfullyUpdatedApplicationWithNoTrxDetails = 'Updated membership application but failed to retrieve transaction.';
export const errorUpdatingApplicationWithNoTrxDetails = 'Failed to update membership application when no transaction details were received.';
export const retryingUpdateApplication = 'Retrying Update Application';
export const errorUpdatingApplicationAfterTrxSuccess = 'Failed to update membership application when the transaction was successful.';
export const retryingProcessingPayment = 'Retrying Processing Payment';
export const errorPostingToGPAfterSuccessfulTrx = 'Failed to write data to GP after successful transaction.';
export const userTriedToReloadOrClosePage = 'User tried to reload or close the page.';
export const errorUpdatingApplicationForNotifyingAuthorizer = 'Failed to updated membership application with application when authorizer is required';
export const errorNotifyingGivenAuthorizer = 'Error notifying given authorizer.';
export const errorNotifyingAuthorizer = 'An unexpected error occurred when notifying the given authorizer. Please try again.';

export const writeToLogs = (message: string, payload?: any, error?: any): any => {
    let obj: any = {
        'message': message,
        'browser': {
            name: browserName,
            version: browserVersion,
            os: osName,
            osVersion: osVersion,
            isMobile: isMobile,
            isTablet: isTablet
        },
    };
    if (payload) {
        obj = {...obj, payload: payload}
    }
    if (error) {
        obj = {...obj, error: error}
    }
    return obj;
}
export const generateTeamsNotificationPayload = (component: string, subject: string, message: string, request?: any, errorStack?: any) => {
    return {
        'system': 'OMAPP',
        'timestamp': new Date().toJSON().toString(),
        'component': component,
        'subject': subject,
        'message': message,
        ...(request ? {'request': JSON.stringify(request)} : {}),
        ...(errorStack ? {'stackTrace': JSON.stringify(errorStack)} : {})
    }
}
export const generateApplicationErrorEmail = (token: string, application: MembershipApplication, config: AppConfig, response: any, errorMsg: string) => {
    return {
        service_id: config.emailJSServiceId,
        template_id: config.emailJSApplicationUpdateFailedTemplate,
        user_id: config.emailJSUserId,
        template_params: {
            token: token,
            application: JSON.stringify(application),
            application_id: application.membershipApplicationId,
            response: JSON.stringify(response),
            error_msg: errorMsg
        }
    };
}
export const generateGPErrorEmail = (application: MembershipApplication, config: AppConfig, payment: Partial<Payment>, key?: string) => {
    return {
        service_id: config.emailJSServiceId,
        template_id: config.emailJSApplicationPostToGpFailedTemplate,
        user_id: config.emailJSUserId,
        template_params: {
            member_name: application.person.first +  ' ' + application.person.last,
            customer_id: application.customerID,
            amt: payment?.amount,
            trxKey: key ? key : '',
            method_of_payment: payment?.paymentMethod,
            application_id: application.membershipApplicationId,
            application: JSON.stringify(application)
        }
    };
}
export const getPdfBlobAnchorElement = (token: string, response: any) => {
    const invoicePdfBlob = new Blob([response], {type: 'application/pdf'});
    const link = document.createElement('a');
    link.href = URL.createObjectURL(invoicePdfBlob);
    link.download = token + "_" + new Date().getTime() + ".pdf";
    return link;
}
export const getErrorMessageForResultCode = (resultCode: string, status: string)  => {
    if (resultCode === '' && status === 'MoreInfo') {
        return errorStatusMoreInfo;
    } else if (resultCode === 'REJECT') {
        return errorCodeREJECT;
    } else {
        // might still need this as we don't know all the error codes it will return
        switch (resultCode) {
            case '11': return errorCode11;
            case '12': return errorCode12;
            case '19': return errorCode19;
            case '22': return errorCode22;
            case '23': return errorCode23;
            case '24': return errorCode24;
            case '26': return errorCode26;
            case '30': return errorCode30;
            case '50': return errorCode50;
            case '51': return errorCode51;
            case '102': return errorCode102;
            case '104': return errorCode104;
            case '109': return errorCode109;
            case '114': return errorCode114;
            case '115': return errorCode115;
            case '125':
            case '126':
            case '127':
            case '128': return errorCode12x;
            default: return errorCodeDefault;
        }
    }
}
export const isSystemError = (code: string) => {
    return code === '26' || code === '102';
}
export const getDefaultPaymentObject = (membershipApplication: MembershipApplication, methodOfPayment: string, amount: string)=> {
    const payment: Partial<Payment> = {
        firstName: membershipApplication.person.first,
        lastName: membershipApplication.person.last,
        address1: sanitizeValue(membershipApplication.company.address1),
        address2: sanitizeValue(membershipApplication.company.address2 || ''),
        city: sanitizeValue(membershipApplication.company.city),
        state: sanitizeValue(membershipApplication.company.state),
        zip: sanitizeValue(membershipApplication.company.zip),
        countryCode: sanitizeValue(membershipApplication.company.country),
        billingAddress1: sanitizeValue(membershipApplication.billingInfo.address1),
        billingAddress2: sanitizeValue(membershipApplication.billingInfo.address2 || ''),
        billingCity: sanitizeValue(membershipApplication.billingInfo.city),
        billingState: sanitizeValue(membershipApplication.billingInfo.state),
        billingCountryCode: sanitizeValue(membershipApplication.billingInfo.country),
        billingZip: sanitizeValue(membershipApplication.billingInfo.zip),
        email: membershipApplication.person.email,
        mobilePhone: sanitizeValue(membershipApplication.person.mobilePhone || ''),
        workPhone: membershipApplication.person.workPhone,
        accountName: membershipApplication.person.first + " " + membershipApplication.person.last,
        phoneExtension: null,
        companyName: membershipApplication.company.name,
        groupType: membershipApplication.groupType,
        billingFrequency: membershipApplication.billingFrequency,
        customerCode: membershipApplication.customerID,
        paymentMethod: methodOfPayment,
        amount: amount
    }
    return payment;
}
export const getCCPaymentObject = (membershipApplication: MembershipApplication, transaction: Transaction, methodOfPayment: string, amount: string)=> {
    let payment: Partial<Payment> = getDefaultPaymentObject(membershipApplication, methodOfPayment, amount);
    payment.billingAddress1 = sanitizeValue(transaction.card.billTo.line1);
    payment.billingAddress2 = sanitizeValue(transaction.card.billTo.line2);
    payment.billingCity = sanitizeValue(transaction.card.billTo.city);
    payment.billingState = sanitizeValue(transaction.card.billTo.state);
    payment.billingZip = sanitizeValue(transaction.card.billTo.zip);
    payment.billingCountryCode = sanitizeValue(transaction.card.billTo.country);
    payment.amount = transaction.amount.toString();
    payment.accountName = transaction.card.cardHolder.firstName + ' ' + transaction.card.cardHolder.lastName;
    payment.creditCardNumber = transaction.card.account;
    payment.ccExpirationDate = transaction.card.expDate;
    payment.originationId = transaction.trxResponse.originationID;
    return payment;
}
