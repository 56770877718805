import React, {FC, Fragment, useContext, useEffect} from 'react';
import './App.css';
import Container from "./layout/Container";
import useTheme from "./hooks/use-theme";
import AppContext from "./store/app-context";

const App: FC = () => {
    // TODO: comment the below
    const { theme, toggleTheme } = useTheme();
    const {setAppContext} = useContext(AppContext);
    useEffect(() => {
        setAppContext();
    }, [setAppContext])
    return (
        <Fragment>
            {/*<h1>Current Theme: {theme}</h1>*/}
            {/*<button onClick={toggleTheme}>Toggle Theme</button>*/}
            <Container/>
        </Fragment>
    );
}

export default App;
