import React, {createContext, FC, PropsWithChildren, useContext, useEffect, useState} from "react";
import {useParams, useNavigate, useSearchParams} from 'react-router-dom';
import MembershipApplicationContext from "../store/membership-application-context";
import LoadingSpinner from "../components/LoadingSpinner/LoadingSpinner";

const ForceNavigateContext = createContext<(path: string) => void>(() => {});

export const useForceNavigate = () => {
    return useContext(ForceNavigateContext);
};

const TokenValidationGuard: FC<PropsWithChildren> = ({ children }) => {
    const { token } = useParams();
    const [searchParams] = useSearchParams();
    const forApprover = searchParams.get("forApprover");
    const navigate = useNavigate();
    const {
        membershipApplication,
        getMembershipApplication,
        getIpAddress,
        isLoading,
        error,
        isProcessingInBackground,
    } = useContext(MembershipApplicationContext);

    const [forceNavigatePath, setForceNavigatePath] = useState<string | null>(null);
    const [blockDefaultNavigation, setBlockDefaultNavigation] = useState<boolean>(false);

    // This useEffect ensures that we get the membership application data once the token is available
    useEffect(() => {
        if (token) {
            getMembershipApplication(token);
            getIpAddress(token);
        }
    }, [getMembershipApplication, getIpAddress, token]);

    // This effect is triggered when a forceNavigatePath is set
    useEffect(() => {
        if (forceNavigatePath) {
            console.log('Navigating to custom path:', forceNavigatePath);
            setBlockDefaultNavigation(true); // Block default navigation temporarily
            navigate(forceNavigatePath, { replace: true }); // Navigate to the forced path
            setForceNavigatePath(null); // Reset the forceNavigatePath to avoid infinite loop
        }
    }, [forceNavigatePath, navigate]);

    // Effect that handles default navigation
    useEffect(() => {
        if (!isLoading && !blockDefaultNavigation && !isProcessingInBackground) {
            if (error) {
                console.log("Navigating to expired link due to error...");
                navigate('/expired-link', { replace: true });
            } else if (membershipApplication && !membershipApplication.stepOneSaved) {
                console.log('Navigating to membership commitments');
                navigate(`/${token}/membership-commitments`, { replace: true });
            } else if (membershipApplication && membershipApplication.stepOneSaved && !membershipApplication.stepTwoSaved) {
                console.log('Navigating to member information');
                navigate(`/${token}/member-information`, { replace: true });
            } else if (membershipApplication && membershipApplication.stepOneSaved && membershipApplication.stepTwoSaved && !membershipApplication.stepThreeSaved) {
                console.log('isProspectAuthorizer: ' + membershipApplication.isProspectAuthorizer);
                console.log('membershipApplication.authorizerNotified: ' + membershipApplication.authorizerNotified);
                if (membershipApplication.isProspectAuthorizer !== null && membershipApplication.isProspectAuthorizer === false
                    && membershipApplication.authorizerNotified !== null && membershipApplication.authorizerNotified && forApprover === null) {
                    // only approver can navigate
                    navigate(`/thank-you?token=${token}&sentForApprover=true`, { replace: true });
                } else {
                    console.log('Navigating to payment information, forApprover=' + forApprover);
                    forApprover === null
                        ? navigate(`/${token}/payment-information`, {replace: true})
                        : navigate(`/${token}/payment-information?forApprover=true`, {replace: true})
                }
            } else if (membershipApplication && membershipApplication.stepThreeSaved && membershipApplication.submitted) {
                console.log('Navigating to thank you');
                navigate(`/thank-you?token=${token}${membershipApplication.methodOfPayment === 'INV' ? '&isInv=true' : ''}`, { replace: true });
            }
        }
        console.log('forApprover: ' + forApprover);
    }, [blockDefaultNavigation, error, forApprover, isLoading, isProcessingInBackground, membershipApplication, navigate, token]);

    const forceNavigate = (path: string) => {
        console.log('Setting path for forceNavigate: ', path);
        setForceNavigatePath(path);
    };

    return (
        <ForceNavigateContext.Provider value={forceNavigate}>
            {isLoading && <LoadingSpinner/>}
            {children}
        </ForceNavigateContext.Provider>
    );
};

export default TokenValidationGuard;
