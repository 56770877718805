import {FC} from "react";
import Step from "./Step";
import classes from "./StepNavigation.module.css";
import {useParams} from "react-router-dom";

type MenuItem = {
    id: number;
    label: string;
    isCurrentStep: boolean;
    canNavigate: boolean;
}

const StepNavigation: FC<{currentStep: number, canNavigateToStep1: boolean, canNavigateToStep2: boolean, canNavigateToStep3: boolean, asyncFunction: any}> = ({currentStep, canNavigateToStep1, canNavigateToStep2, canNavigateToStep3, asyncFunction}) => {
    const {token} = useParams<{ token: string }>();
    const items: MenuItem[] = [{
        id: 1,
        label: 'Membership Commitments',
        isCurrentStep: currentStep === 1,
        canNavigate: canNavigateToStep1
    }, {
        id: 2,
        label: 'Member Information',
        isCurrentStep: currentStep === 2,
        canNavigate: canNavigateToStep2
    }, {
        id: 3,
        label: 'Payment Information',
        isCurrentStep: currentStep === 3,
        canNavigate: canNavigateToStep3
    }];
    const navigateTo = (step: number) => {
        console.log('step: ' + step)
        if (step === 1 && currentStep !== 1 && canNavigateToStep1) {
            console.log('before async in step 1');
            asyncFunction(`/${token}/membership-commitments`);
        } else if (step === 2 && currentStep !== 2 && canNavigateToStep2) {
            asyncFunction(`/${token}/member-information`);
        } else if (step === 3 && currentStep !== 3 && canNavigateToStep3) {
            asyncFunction(`/${token}/payment-information`);
        }
    }
    return (
        <div className={classes['step-navigation']}>
            {items.map((item: MenuItem) => (
                <Step step={item.id} text={item.label} isDisabled={!item.isCurrentStep} key={item.id} canNavigate={item.canNavigate} onClickHandler={navigateTo} />
            ))}
        </div>
    );
}

export default StepNavigation;
