import React, {FC, useContext, useEffect, useState} from "react";
import Header from "../../components/Header/Header";
import Card from "../../components/Card/Card";
import NavigationButton from "../../components/NavigationButton/NavigationButton";
import AppContext from "../../store/app-context";
import StepNavigation from "../../components/StepNavigation/StepNavigation";
import MembershipApplicationContext from "../../store/membership-application-context";
import IFrameLink from "../../components/IFrameLink/IFrameLink";
import Footer from "../../components/Footer/Footer";
import {useNavigate, useParams} from "react-router-dom";
import useHttp from "../../hooks/use-http";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import {
    errorUpdatingApplication,
    loadingMembershipCommitments,
    updateMembershipApplicationEndpoint,
    updatingApplication,
    writeToLogsEndpoint,
    writeToLogs
} from "../../utils/Constants";
import {MembershipApplication} from "../../models/MembershipApplication";
import {faAngleRight} from "@fortawesome/free-solid-svg-icons/faAngleRight";
import {useForceNavigate} from "../../guards/TokenValidationGuard";

const MembershipCommitments: FC = () => {
    const {config} = useContext(AppContext);
    const {token} = useParams<{ token: string }>();
    const {isLoading, sendRequest} = useHttp();
    const navigate = useNavigate();
    const [error, setError] = useState<any>(null);
    const forceNavigate = useForceNavigate();
    const {membershipApplication, updateMembershipApplication, ipAddress} = useContext(MembershipApplicationContext);
    useEffect(() => {
        sendRequest({
            url: writeToLogsEndpoint,
            method: 'POST',
            token: token,
            body: writeToLogs(loadingMembershipCommitments(token))
        });
    }, [sendRequest, token]);
    const saveApplication = () => {
        const membershipApplicationCopy: MembershipApplication = {
            ...membershipApplication!,
            stepOneSaved: true,
            termsAccepted: true,
            termsAcceptedIp: ipAddress!,
            termsVersion: membershipApplication!.agreement.version,
        }
        sendRequest({
            url: writeToLogsEndpoint,
            method: 'POST',
            token: token,
            body: writeToLogs(updatingApplication, membershipApplicationCopy)
        });
        updateApplication(membershipApplicationCopy);
    };
    const updateApplication = (membershipApplicationCopy: MembershipApplication) => {
        setError(null);
        sendRequest({
            url: updateMembershipApplicationEndpoint(token!),
            method: 'PATCH',
            token: token,
            body: membershipApplicationCopy
        }, (application: MembershipApplication) => {
            updateMembershipApplicationContext(application).then(() => {
                navigate(`/${token}/member-information`, { replace: true });
            })
        }, (err: any) => {
            sendRequest({
                url: writeToLogsEndpoint,
                method: 'POST',
                token: token,
                body: writeToLogs(errorUpdatingApplication, membershipApplicationCopy, err),
            });
            setError(errorUpdatingApplication);
        });
    }
    const redirectTo = (url: string) => {
        forceNavigate(url);
    }
    const updateMembershipApplicationContext = async (application: MembershipApplication) => {
        updateMembershipApplication(application, true);
    }
    return (
        <div>
            {isLoading && <LoadingSpinner/>}
            <Header/>
            <StepNavigation
                currentStep={1}
                canNavigateToStep1={true}
                canNavigateToStep2={membershipApplication?.stepOneSaved || false}
                canNavigateToStep3={membershipApplication?.stepTwoSaved || false}
                asyncFunction={redirectTo}
            />
            <div className="row">
                Welcome to Vistage, the leading business advisory and executive coaching organization. Once we’ve
                approved your application, a Client Services Advisor will reach out to acquaint you with all your
                membership benefits, including the My Vistage website and mobile app. My Vistage is your gateway
                to {config?.totalMemberCount} worldwide for peer-to-peer connections, industry and interest-based
                networks, and exclusive
                resources for CEOs.
            </div>
            <div className="row">
                Vistage works because of the integrity of our members. To ensure you and your fellow members get the
                greatest benefit from your group, we ask that everyone participates fully in group meetings, respects
                group confidentiality and honors the membership commitments outlined below. We are excited about your
                interest in becoming a member and look forward to helping you make great decisions that benefit your
                company, your family and your community.
            </div>
            <h3 className="form-heading margin-top-20">Membership commitments</h3>
            <Card>
                {error && <div className="error-message">{error}</div>}
                <div dangerouslySetInnerHTML={{__html: membershipApplication?.agreement.commitment || ''}}></div>
                <div className="margin-top-20">
                    See complete Membership Terms and Conditions Agreement contained <IFrameLink textToDisplay={"herein"} heading={"Membership Terms and Conditions agreement"} url={membershipApplication?.agreement.termsLink}/>.
                </div>
            </Card>
            <h4 className="form-heading margin-top-20">Your satisfaction is our commitment</h4>
            <p className="margin-0">
                If you have questions or wish to discuss how we can enhance your member experience, please contact Client Services:
            </p>
            <p className="margin-0">Toll free: 800.274.2367</p>
            <p className="margin-0">Email: ClientServices@vistage.com</p>
            <p className="margin-0"><b>Client Services is committed to answering your questions within one business day, Monday through Friday, 7:00 a.m. to 5:00 p.m. PT.</b></p>
            <div className="margin-top-20 nav-buttons-row">
                <NavigationButton text={"Continue"} onClickEventHandler={saveApplication} icon={faAngleRight} iconPlacement={"right"}/>
            </div>
            <Footer version={membershipApplication?.agreement.version || ''}/>
        </div>
    )
}

export default MembershipCommitments;
