import {FC} from "react";
import classes from "./Step.module.css"

const Step: FC<{step: number, text: string, isDisabled: boolean, canNavigate: boolean, onClickHandler: any}> = ({step, text, isDisabled, canNavigate, onClickHandler}) => {
    const navigateToStepHandler = () => {
        onClickHandler(step);
    }
    return (
        <div className={`${classes.step} col-md-4 col-sm-4 col-12 ${ isDisabled ? classes['step-disabled'] : ''}`}>
            <div className={`${classes['step-link']}`}>
                <span className={`${classes['step-circle']} ${canNavigate ? classes['can-navigate'] : ''}`} onClick={navigateToStepHandler}>{step}</span>
                <span className={classes['step-label']}>{text}</span>
            </div>
        </div>
    );
}

export default Step;
