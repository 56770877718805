import React, {FC, Fragment, useState} from "react";
import Card from "../../components/Card/Card";
import NavigationButton from "../../components/NavigationButton/NavigationButton";
import Input from "../../components/Input/Input";
import useInput from "../../hooks/use-input";
import {isEmailValid, isNotEmpty} from "../../utils/Constants";

const AuthorizedSignerForm: FC<{name: string, title: string, email: string, sendEmail: any}> = ({name, title, email, sendEmail}) => {
    const [formHasErrors, setFormHasErrors] = useState<boolean>(false);
    const {
        value: authorizerNameValue,
        valueChangeHandler: authorizerNameValueChangeHandler,
        hasError: authorizerNameValueHasError
    } = useInput(name || '', isNotEmpty);
    const {
        value: authorizerTitleValue,
        valueChangeHandler: authorizerTitleValueChangeHandler,
        hasError: authorizerTitleValueHasError
    } = useInput(title || '', isNotEmpty);
    const {
        value: authorizerEmailValue,
        valueChangeHandler: authorizerEmailValueChangeHandler,
        hasError: authorizerEmailValueHasError
    } = useInput(email || '', (value: string) => {return isNotEmpty(value) && isEmailValid(value)});
    const sendEmailHandler = () => {
        setFormHasErrors(false);
        if (authorizerNameValueHasError || authorizerTitleValueHasError || authorizerEmailValueHasError) {
            setFormHasErrors(true);
        }
        sendEmail(authorizerNameValue, authorizerTitleValue, authorizerEmailValue);
    }
    return (
        <Fragment>
            <h3 className="form-heading margin-top-20">Sponsoring Company Authorized Signer</h3>
            <Card>
                <div className={"row"}>
                    <div className={"col-md-12"}>
                        Please provide the name and contact information of an authorized individual from the Sponsoring Company who can complete the remainder of this application.
                    </div>
                </div>
                <Input
                    label={"Name"}
                    type={"text"}
                    required={true}
                    readOnly={false}
                    value={authorizerNameValue}
                    styles={formHasErrors && authorizerNameValueHasError ? "input-has-error" : ""}
                    onChange={authorizerNameValueChangeHandler}
                />
                <Input
                    label={"Title"}
                    type={"text"}
                    required={true}
                    readOnly={false}
                    value={authorizerTitleValue}
                    styles={formHasErrors && authorizerTitleValueHasError ? "input-has-error" : ""}
                    onChange={authorizerTitleValueChangeHandler}
                />
                <Input
                    label={"Email Address"}
                    type={"text"}
                    required={true}
                    readOnly={false}
                    value={authorizerEmailValue}
                    styles={formHasErrors && authorizerEmailValueHasError ? "input-has-error" : ""}
                    onChange={authorizerEmailValueChangeHandler}
                />
            </Card>
            <div className="margin-top-20 nav-buttons-row">
                <NavigationButton text={"Send Email"} onClickEventHandler={sendEmailHandler} icon={""} iconPlacement={""}/>
            </div>
        </Fragment>
    );
}
export default AuthorizedSignerForm;
